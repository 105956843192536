// src/pages/blog/{microcmsBlog.blogId}.js
import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Moment from 'react-moment';

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "../../css/hero.css"

const newsDetailPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={data.microcmsPortalGroup.accountName} ogImage={data.microcmsPortalGroup.thumbnail.url} />

      <section className="hero">
        <figure>
          {/* <StaticImage src="../../images/sample.png" alt="hero" style={{ width: "100%" }} /> */}
          <img src={data.microcmsPortalGroup.thumbnail.url} />
        </figure>
      </section>

      <section className="bg-base-main px-8 py-4">
        <div className="max-w-5xl mx-auto">

          <div className="flex flex-wrap">
            <div className="">
              <Link to={`/`}>
                <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/group/1`}>
                <button className="text-secondry-main text-sm underline">団体をみる</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <span className="text-base-dark text-sm non-underline">
                {data.microcmsPortalGroup.accountName}
              </span>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-base-main mx-8">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-col md:flex-row w-full py-6">
            <img src={data.microcmsPortalGroup.accountThumbnail.url} className="rounded-lg" width="180" height="180" />
            <div className="md:px-8 py-8 md:py-0">
              <h1 className="text-xl sm:text-4xl text-base-dark font-bold leading-none mb-3">
                {data.microcmsPortalGroup.accountName}
              </h1>
              <div className="flex space-x-4 md:pt-16">
                {(() => {
                  if (data.microcmsPortalGroup.snsUrlFb != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsUrlFb}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_facebook.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.snsurl != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsurl}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_twitter.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.hpurl != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.hpurl}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_hp.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.snsUrlInstagram != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsUrlInstagram}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_insta.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-base-main py-8 mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="p-8 border-2 border-base-dark bg-white rounded-lg">
            <div className="font-bold text-2xl">
              この団体とは？
            </div>
            <div className="postbody font-medium py-8"
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsPortalGroup.abstract}`,
              }}
            />
          </div>

        </div>
      </section>

      <section className="bg-base-main px-8">
        <div className="max-w-5xl mx-auto">

          <div className="p-8 border-2 border-base-dark bg-white rounded-lg">
            <div className="postbody font-medium"
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsPortalGroup.body}`,
              }}
            />
          </div>

        </div>
      </section>

      <section className="p-8">
        <div className="container max-w-5xl mx-auto">

          <div className="flex justify-around py-8">
            <div className="flex">
              <h2 className="text-4xl text-base-dark font-bold leading-none mb-3">プロジェクト</h2>
            </div>
          </div>

          <div className="flex flex-wrap justify-between w-full">
            <div className="w-full">
              {data.allMicrocmsPortalProject.edges.map(({ node }) => {
                if (node.accountName.id == data.microcmsPortalGroup.portalGroupId) {
                  return (
                    <div className="w-full py-4" key={node.portalProjectId}>
                      <div className="overflow-hidden rounded-xl shadow-xl bg-white flex">
                        <Link to={`/project-detail/${node.portalProjectId}`} className="hover:opacity-60">
                          <img src={node.thumbnail.url} className="m-4" width="326" height="240" />
                        </Link>

                        <div className="px-3 md:px-4 flex flex-col justify-around">
                          <div className="flex flex-col">
                            <div className="flex py-4">
                              <Link to={`/project-detail/${node.portalProjectId}`} className="font-bold text-md text-base-dark hover:opacity-60">
                                {node.title}
                              </Link>
                            </div>
                            <div className="flex">
                              <img src={node.accountName.accountThumbnail.url} className="" width="49" height="48" />
                              <div className="p-3 md:px-4 text-base-dark">
                                {node.accountName.accountName}
                              </div>
                            </div>
                          </div>
                          <div className="text-base-dark space-x-4 flex justify-between">
                            <div className="text-md text-base-dark">
                              {/* <Link to={`/news/`} className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1 hover:bg-blue-600">
                              <span className="text-xs">{node.category}</span>
                            </Link> */}
                              <span className="bg-base-dark rounded-full font-bold text-md text-white px-4 py-1">
                                <span className="text-xs">{node.category}</span>
                              </span>
                            </div>
                            <div>
                              <Moment className="text-md" format="YYYY/MM/DD">{node.updatedAt}</Moment>　更新
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                }
              }
              )}
            </div>
          </div>

        </div>
      </section>

      {/* <section className=" py-8 mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="flex justify-around">
            <h2 className="text-4xl text-base-dark font-bold leading-none mb-3">イベント情報・お知らせ</h2>
          </div>

          <div className="flex flex-wrap">
            <div className="w-full py-6">
              {data.allMicrocmsHpNews.edges.map(({ node }) => (
                <div className=" border-b" key={node.hpNewsId}>
                  <div className="container mx-auto flex flex-wrap">
                    <div className="w-full md:w-1/3 py-6 flex flex-col flex-grow flex-shrink">
                      <div className="flex-1  py-4">
                        <div className="flex flex-wrap no-underline hover:no-underline">
                          <Moment className="font-bold text-md text-base-dark" format="YYYY.MM.DD">{node.createdAt}</Moment>
                          <span className="font-bold text-md text-base-dark px-6">
                            <Link to={`/news`} className="bg-base-dark rounded-full font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600">
                              {node.category}
                            </Link>
                          </span>
                          <Link to={`/news-detail/${node.hpNewsId}`} className="font-bold text-md px-6 hover:underline">
                            {node.title}
                          </Link>
                          <div className="postbody font-medium"
                            dangerouslySetInnerHTML={{
                              __html: `${data.microcmsPortalGroup.body}`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-base-main py-8 mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="flex justify-around py-8">
            <h2 className="text-4xl text-base-dark font-bold leading-none mb-3">イベント情報・お知らせ</h2>
          </div>

          <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
            {data.allMicrocmsPortalNews.edges.map(({ node }) => (
              <div className="border-b py-4" key={node.portalNewsId}>
                <div className="text-md text-base-dark">
                  <Moment className="text-md" format="YYYY.MM.DD">{node.createdAt}</Moment>
                  <span className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1">
                    <span className="text-xs">{node.category}</span>
                  </span>
                </div>
                <div className="py-2">
                  <Link to={`/news-detail/${node.portalNewsId}`} className="font-bold text-md hover:underline">
                    {node.title}
                  </Link>
                </div>
                {/* <Link to={`/news-detail/${node.portalNewsId}`} className="text-md hover:underline">
                  <div dangerouslySetInnerHTML={{
                    __html: `${node.body}`,
                  }} />
                </Link> */}
              </div>
            ))}
          </div>

        </div>
      </section>

    </Layout>
  )
}

export default newsDetailPage

export const query = graphql`
  query($id: String!) {
    microcmsPortalGroup(id: { eq: $id }) {
        body
        category
        portalGroupId
        accountName
        hpurl
        snsurl
        snsUrlFb
        snsUrlInstagram
        abstract
        accountThumbnail {
          url
        }
        thumbnail {
          url
        }
    }
    allMicrocmsPortalNews(sort: { fields: [createdAt], order: ASC }) {
      totalCount
      edges {
        node {
          body
          title
          category
          portalNewsId
          updatedAt
          createdAt
        }
      }
    }
    allMicrocmsPortalProject {
      totalCount
      edges {
        node {
          portalProjectId
          thumbnail {
            url
          }
          title
          accountName {
            id
            accountName
            thumbnail {
              url
            }
            accountThumbnail {
              url
            }
          }
          body
          category
        }
      }
    }
  }
`
